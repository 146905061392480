
import { Options, Vue } from 'vue-class-component';
import { AttributeView, Media, ReviewResource } from '@okendo/reviews-common';

import Banner from '@/shared-components/Banner.vue';
import PoweredByOkendo from '@/shared-components/PoweredByOkendo.vue';
import Profile from '@/shared-components/profile/Profile.vue';
import Terms from '@/shared-components/Terms.vue';
import MediaUploader from '@/shared-components/MediaUploader.vue';
import Attribute from '@/shared-components/Attribute.vue';
import ReviewProgress from '@/views/review-enhancement/components/ReviewProgress.vue';
import { formatDuration, formatMediaToTranscode } from '@/utils/mediaUploadUtil';
import store from '@/store';
import { APIError, postAnalytics, postErrorAnalytics, updateReview } from '@/utils/api';
import ViewState, { ViewStateStatus } from '@/shared-components/ViewState.vue';
import AchievementHeader from '@/shared-components/AchievementHeader.vue';
import { StoreMethod } from '@/store/storeTypings';
import { verifyProductId } from '@/utils/productUtils';
import { isReviewUpgradeable } from '@/utils/upgradeReviewUtil';
import { AxiosResponse } from 'axios';
import { ErrorCode } from '@/utils/errorUtils';

@Options({
    components: {
        AchievementHeader,
        Attribute,
        Banner,
        MediaUploader,
        PoweredByOkendo,
        Profile,
        ReviewProgress,
        Terms,
        ViewState
    },
    store
})
export default class ReviewEnhancement extends Vue {
    isSending = false;
    mediaBlocks: MediaBlock[] = [];
    mediaFromReview: Media[] = [];
    hasSubmitted = false;
    viewState: ViewStateStatus = 'loading';
    errorCode: ErrorCode = 'unknown';
    hasProfileImageOnLoad = false;
    socialConnectionIdOnLoad?: string;

    mounted(): void {
        window.scrollTo(0, 0);
        postAnalytics({ eventName: 'page-open' });

        this.mediaFromReview = this.getMediaFromReview();
        this.loadReviewEnhancement();
    }

    async loadReviewEnhancement(): Promise<void> {
        if (this.isPreviewMode) {
            this.viewState = 'content';
            return;
        }

        if (!isReviewUpgradeable(this.mediaFromReview)) {
            this.$router.replace({
                path: 'post-review',
                query: this.$route.query
            });

            return;
        }

        try {
            await verifyProductId(this.$route.query.productId);
            this.hasProfileImageOnLoad = !!store.state.profile.reviewerProfile.imageUrl;
            this.socialConnectionIdOnLoad = store.state.profile.reviewerProfile.socialMediaUserId;
            if (!store.state.review.editToken) {
                // Instead of an error screen, just redirect to the "Post Review" page
                await this.$router.replace({
                    path: '/post-review',
                    query: this.$route.query
                });
            }

            this.viewState = 'content';
        }
        catch (error) {
            postErrorAnalytics(error);
            this.viewState = 'error';
        }
    }

    getMediaFromReview(): Media[] {
        const { review, videoThumbnails } = store.state.review;
        const reviewMedia = review?.media || [];
        return [...reviewMedia, ...videoThumbnails];
    }

    get isMediaUploadSectionVisible(): boolean {
        if (!this.isMediaCaptureEnabled) {
            return false;
        }

        const { reward } = store.state.banner;
        return !!reward.imageRewardText || !!reward.videoRewardText || !this.mediaFromReview.length;
    }

    get hasMedia(): boolean {
        return !!this.mediaBlocks.length;
    }

    get isOptedIn(): boolean {
        return store.state.profile.isOptedIn;
    }

    get remainingProductsToReview() {
        return store.state.order.remainingProductsToReview;
    }

    get isMediaCaptureEnabled(): boolean {
        const { settings } = store.state.settings;
        return !settings.disableMediaCapture;
    }

    get attributesList(): AttributeView.DataAttribute[] | undefined {
        return store.state.product.product.attributes;
    }

    get actionButtonText(): string {
        return this.isEligibleForUpgrade ? 'Upgrade Review' : this.hasUpdated ? 'Update Review' : 'Skip';
    }

    get hasUpdated(): boolean {
        return this.hasMedia || (!this.hasProfileImageOnLoad && !!store.state.profile.reviewerProfile.imageUrl);
    }

    get isEligibleForUpgrade(): boolean {
        if (!store.state.banner.reward) {
            return false;
        }

        const { imageRewardText, videoRewardText, socialRewardText } = store.state.banner.reward;
        const { socialMediaType, customAvatar } = store.state.profile.reviewerProfile;

        const hasImageUpgrade = !!(imageRewardText && this.mediaBlocks.filter(mediaBlock => mediaBlock.type === 'image').length);
        const hasVideoUpgrade = !!(videoRewardText && this.mediaBlocks.filter(mediaBlock => mediaBlock.type === 'video').length);
        const hasSocialUpgrade = !!(socialRewardText && (socialMediaType || customAvatar));

        return hasImageUpgrade || hasVideoUpgrade || hasSocialUpgrade;
    }

    get isPreviewMode(): boolean {
        return store.state.subscriber.previewMode;
    }

    get isTestMode(): boolean {
        return store.state.order.reviewRequestId === 'test';
    }

    formatDuration(duration: number): string {
        return formatDuration(duration);
    }

    get isEmailInputVisible(): boolean {
        const { reviewRequestId } = store.state.order;
        return reviewRequestId === 'test';
    }

    get hasUpdatedSocialLogin(): boolean {
        return store.state.profile.reviewerProfile.socialMediaUserId !== this.socialConnectionIdOnLoad;
    }

    get hasUpdatedAvatar(): boolean {
        return store.state.review.review?.reviewer.avatarUrl !== store.state.profile.reviewerProfile.imageUrl ||
            store.state.review.review?.reviewer.customAvatar?.streamId !== store.state.profile.reviewerProfile.customAvatar?.streamId;
    }

    get isSocialLoginEnabled(): boolean {
        const { settings } = store.state.settings;
        return !settings.disableSocialLogin;
    }

    get isProfilePictureUploadEnabled(): boolean {
        const { settings } = store.state.settings;
        return !settings.disableProfilePictureUpload;
    }

    get isProfileSectionVisible(): boolean {
        return !this.hasProfileImageOnLoad && (this.isSocialLoginEnabled || this.isProfilePictureUploadEnabled);
    }

    async submitUpdatedReview(): Promise<void> {
        if (this.isSending || this.isPreviewMode) {
            return;
        }

        if (this.mediaBlocks.some(media => media.progress < 100)) {
            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: this.$t('Please wait for media to finish uploading before submitting'),
                    status: 'fail'
                }
            });
            return;
        }

        this.hasSubmitted = true;
        this.isSending = true;

        const {
            order: { elementId, reviewRequestId },
            profile: { reviewerProfile },
            review: { editToken, review }
        } = store.state;

        if (!editToken || !review?.reviewId) {
            throw Error('Should not be possible to be on this page without an edit token');
        }

        const sequenceContext = (reviewRequestId && elementId) ? {
            reviewRequestId,
            elementId
        } : undefined;

        const reviewToUpdate: ReviewResource.UpdateReview.Review = {
            editToken,
            isTestMode: this.isTestMode,
            reviewer: {
                email: reviewerProfile.email || '',
                name: reviewerProfile.name || ''
            },
            sequenceContext
        };

        if (this.hasMedia) {
            reviewToUpdate.media = formatMediaToTranscode(this.mediaBlocks);
        }

        if (this.hasUpdatedSocialLogin) {
            reviewToUpdate.reviewer.socialConnection = reviewerProfile.socialMediaType;
            reviewToUpdate.reviewer.socialConnectionUserId = reviewerProfile.socialMediaUserId;
        }

        if (this.hasUpdatedAvatar) {
            reviewToUpdate.reviewer.avatarUrl = reviewerProfile.imageUrl;
            reviewToUpdate.reviewer.customAvatarStreamId = reviewerProfile.customAvatar?.streamId;
        }

        try {
            const { reward } = await updateReview(review.reviewId, reviewToUpdate);

            postAnalytics({
                eventName: 'action-updated-review',
                label: 'reviewId',
                value: review.reviewId
            });

            if (reward) {
                store.commit<StoreMethod>({
                    type: 'reward/UPDATE_REWARD',
                    achievedReward: reward.achieved
                });

                if (reward.previouslyAchieved) {
                    store.commit<StoreMethod>({
                        type: 'reward/UPDATE_PREVIOUS_REWARD',
                        previouslyAchievedReward: reward.previouslyAchieved
                    });
                }
            }

            store.commit<StoreMethod>({
                type: 'review/UPDATE_EDIT_TOKEN',
                editToken: undefined
            });

            // Update review
            store.commit<StoreMethod>({
                type: 'review/UPDATE_REVIEW',
                review,
                reviewerName: reviewerProfile.name,
                reviewEnhanced: this.hasUpdated
            });
        }
        catch (error) {
            const isNothingToUpdate = (error as AxiosResponse<APIError>)?.data?.error?.description?.includes('Nothing to update');
            // Need some more validation here? If it's an invalid token, it should say so

            if (isNothingToUpdate) {
                postAnalytics({
                    eventName: 'action-skipped-update',
                    label: 'reviewId',
                    value: review.reviewId
                });
            }
            else {
                this.errorCode = 'product-not-found';
                this.viewState = 'error';
            }
        }

        store.commit<StoreMethod>({
            type: 'profile/UPDATE_IS_OPTED_IN',
            isOptedIn: true
        });

        store.commit<StoreMethod>({
            type: 'product/UPDATE_PREVIOUSLY_REVIEWED',
            isPreviouslyReviewed: false
        });

        this.$router.replace({
            path: 'post-review',
            query: this.$route.query
        });
    }
}
