
import { Options, Vue } from 'vue-class-component';
import Icon from '@/shared-components/Icon.vue';
import store from '@/store';

@Options({
    props: {
        icon: String,
        text: String,
        percentCompleted: Number,
        areAllStepsComplete: Boolean
    },
    components: {
        Icon
    },
    store
})
export default class ProgressStep extends Vue {
    percentCompleted!: number;
    radius = 25;
    strokeWidth = 2;
    innerRadius = this.radius - this.strokeWidth * 2;
    circumference = this.innerRadius * 2 * Math.PI;
    strokeColor = store.state.settings.settings.colors?.progressCompleteStepColor ?? '#0E7D81';

    get strokeDashoffset(): number {
        return this.circumference - this.percentCompleted / 100 * this.circumference;
    }

    get isComplete(): boolean {
        return this.percentCompleted > 99;
    }
}

