import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fbbeb13"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "c-achievement-headers"
}
const _hoisted_2 = {
  key: 0,
  class: "c-achievement-emoji"
}
const _hoisted_3 = {
  key: 1,
  class: "c-achievement-subheading"
}
const _hoisted_4 = {
  key: 1,
  class: "c-achievement-upgrade"
}
const _hoisted_5 = { class: "c-achievement-prize c-achievement-prize--disabled" }
const _hoisted_6 = { class: "c-achievement-prize-icon c-achievement-prize-icon--disabled" }
const _hoisted_7 = { class: "c-achievement-prize-text c-achievement-prize-text--disabled" }
const _hoisted_8 = { class: "c-achievement-upgrade-arrow" }
const _hoisted_9 = { class: "c-achievement-prize" }
const _hoisted_10 = { class: "c-achievement-prize-icon" }
const _hoisted_11 = { class: "c-achievement-prize-text" }
const _hoisted_12 = { class: "c-achievement-prize-icon" }
const _hoisted_13 = { class: "c-achievement-prize-text" }
const _hoisted_14 = {
  key: 3,
  class: "c-achievement-prize c-achievement-prize--transparent"
}
const _hoisted_15 = { class: "c-achievement-prize-icon" }
const _hoisted_16 = { class: "c-achievement-prize-text" }
const _hoisted_17 = {
  key: 4,
  class: "c-achievement-subheading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_tooltip = _resolveComponent("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'u-marginBottomUI--small': _ctx.isReviewEnhancementPage }, "c-achievement"])
  }, [
    (_ctx.areAchievementHeadersVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.hidePartyPopper)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, "🎉"))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass({ 'u-marginBottomUI--xxxSmall': _ctx.achievement.subheading })
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.achievement.headings, (achievementHeading) => {
              return (_openBlock(), _createElementBlock("div", {
                key: achievementHeading,
                class: "c-achievement-heading"
              }, _toDisplayString(_ctx.$t(achievementHeading)), 1))
            }), 128))
          ], 2),
          (_ctx.achievement.subheading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.achievement.subheading)), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.achievement.isCouponUpgrade && _ctx.achievement.previouslyAchieved && _ctx.achievement.coupon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_icon, {
                class: _normalizeClass([{ 'c-icon--white': _ctx.isIncompleteCouponDark }, "c-icon--xSmall"]),
                icon: "gift"
              }, null, 8, ["class"])
            ]),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getAchievedValueText(_ctx.achievement.previouslyAchieved.value, _ctx.achievement.previouslyAchieved.valueType)), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_icon, {
              icon: "arrow-right",
              class: "c-icon--xSmall"
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_icon, {
                class: _normalizeClass([{ 'c-icon--white': _ctx.isCompleteCouponDark }, "c-icon--xSmall"]),
                icon: "gift"
              }, null, 8, ["class"])
            ]),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getAchievedValueText(_ctx.achievement.coupon.value, _ctx.achievement.coupon.valueType)), 1)
          ])
        ]))
      : (_ctx.achievement.value && this.achievement.type === 'coupon')
        ? (_openBlock(), _createBlock(_component_tooltip, {
            key: 2,
            onMouseenter: _ctx.onCouponTooltipMouseenter,
            onMouseleave: _ctx.onCouponTooltipMouseleave,
            hover: false,
            show: _ctx.showCouponTooltip,
            content: _ctx.couponCopied ? _ctx.$t('Copied') : _ctx.$t('Click to copy'),
            class: "c-achievement-tooltip"
          }, {
            default: _withCtx(() => [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyCouponToClipboard && _ctx.copyCouponToClipboard(...args))),
                class: "c-achievement-prize c-achievement-button c-achievement-prize--transparent"
              }, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_icon, {
                    class: _normalizeClass([{ 'c-icon--white': _ctx.isCompleteCouponDark }, "c-icon--xSmall"]),
                    icon: "gift"
                  }, null, 8, ["class"])
                ]),
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.achievement.value), 1)
              ])
            ]),
            _: 1
          }, 8, ["onMouseenter", "onMouseleave", "show", "content"]))
        : (_ctx.achievement.value && this.achievement.type !== 'coupon')
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_icon, {
                  class: _normalizeClass([{ 'c-icon--white': _ctx.isCompleteCouponDark }, "c-icon--xSmall"]),
                  icon: "gift"
                }, null, 8, ["class"])
              ]),
              _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.achievement.value), 1)
            ]))
          : _createCommentVNode("", true),
    (_ctx.achievement.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.$t(_ctx.achievement.description)), 1))
      : _createCommentVNode("", true)
  ], 2))
}