
import { Options, Vue } from 'vue-class-component';

import Icon from '@/shared-components/Icon.vue';
import ProgressStep from './ProgressStep.vue';
import store from '@/store';

@Options({
    components: {
        Icon,
        ProgressStep
    },
    props: {
        hasMedia: Boolean,
        isEligibleForUpgrade: Boolean,
        okeClass: String,
        isSending: Boolean
    },
    emits: ['clickUpgradeButton'],
    store
})
export default class ReviewProgress extends Vue {
    hasMedia!: boolean;
    isEligibleForUpgrade!: boolean;
    // bonusStepsPercentage?: number;
    isStuck = false;
    observer?: IntersectionObserver;

    mounted(): void {
        this.createStickyObserver();
    }

    beforeUnmount(): void {
        this.removeStickyObserver();
    }

    get isAvatarStepComplete(): boolean {
        return !!store.state.profile.reviewerProfile.imageUrl;
    }

    get isReviewStepComplete(): boolean {
        if (store.state.subscriber.previewMode) {
            return true;
        }

        return !!store.state.reviewForm.body || !!store.state.review.review?.body;
    }

    get isMediaStepComplete(): boolean {
        const { review, videoThumbnails } = store.state.review;
        return this.hasMedia || !!review?.media?.length || !!videoThumbnails.length;
    }

    get progressSteps(): StepInfo[] {
        const { disableMediaCapture, disableSocialLogin, disableProfilePictureUpload } = store.state.settings.settings;
        // const { bonusQuestions } = whereverBonusQuestionsAreFrom;

        const progressSteps: StepInfo[] = [{
            icon: 'star-outline',
            text: 'Write Review',
            percentCompleted: this.isReviewStepComplete ? 100 : 0
        }];

        if (!disableMediaCapture) {
            progressSteps.push({
                icon: 'photo-video',
                text: 'Add Photo or Video',
                percentCompleted: this.isMediaStepComplete ? 100 : 0
            });
        }

        if (!disableSocialLogin || !disableProfilePictureUpload) {
            progressSteps.push({
                icon: 'profile',
                text: 'Add Profile Picture',
                percentCompleted: this.isAvatarStepComplete ? 100 : 0
            });
        }

        // if (bonusQuestions) {
        //     progressSteps.push({
        //         icon: 'checkbox',
        //         text: 'Bonus Questions',
        //         percentCompleted: this.bonusStepsPercentage ?? 0
        //     });
        // }

        return progressSteps;
    }

    get areAllStepsComplete(): boolean {
        return this.progressSteps.every(step => step.percentCompleted > 99);
    }

    get actionButtonText(): string {
        return this.isEligibleForUpgrade ? 'Upgrade Review' : 'Update Review';
    }

    clickUpgradeButton(): void {
        this.$emit('clickUpgradeButton');
    }

    createStickyObserver(): void {
        this.observer = new IntersectionObserver(([entry]) => {
            this.isStuck = !entry.isIntersecting;
        });

        this.observer.observe(this.$refs.intersectionTarget as HTMLElement);
    }

    removeStickyObserver(): void {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    leftSpacing(index: number): string {
        const numberOfSteps = this.progressSteps.length;

        // push them all together to the left or evenly space them
        const spacing = this.areAllStepsComplete
            ? 11 + 2 * index
            : (100 / numberOfSteps) * (index + 0.5);

        // subtract half the width of the step to centre each one
        return `calc(${spacing}% - 34px)`;
    }
}

interface StepInfo {
    icon: string;
    text: StepInfoText;
    percentCompleted: number;
}

type StepInfoText = 'Write Review' | 'Add Photo or Video' | 'Add Profile Picture' | 'Bonus Questions';
