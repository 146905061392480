import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-700cffb9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "c-main"
}
const _hoisted_2 = { class: "u-textAlign--center u-fontWeight--semibold u-fontSize-vue--medium u-marginBottomUI--small" }
const _hoisted_3 = {
  key: 0,
  class: "u-marginBottomUI--large"
}
const _hoisted_4 = { class: "u-fontSize-vue--small u-marginBottomUI--xxSmall u-fontColor--secondary" }
const _hoisted_5 = { class: "c-currentMedia u-marginBottomUI--xxSmall" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "c-currentMedia-block-duration"
}
const _hoisted_8 = {
  key: 1,
  class: "u-marginBottomUI--xLarge"
}
const _hoisted_9 = ["aria-label"]
const _hoisted_10 = {
  key: 0,
  class: "c-loader c-loader--medium c-loader--buttonFontColor"
}
const _hoisted_11 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner = _resolveComponent("banner")!
  const _component_achievement_header = _resolveComponent("achievement-header")!
  const _component_review_progress = _resolveComponent("review-progress")!
  const _component_media_uploader = _resolveComponent("media-uploader")!
  const _component_profile = _resolveComponent("profile")!
  const _component_terms = _resolveComponent("terms")!
  const _component_powered_by_okendo = _resolveComponent("powered-by-okendo")!
  const _component_view_state = _resolveComponent("view-state")!
  const _directive_analytics = _resolveDirective("analytics")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.viewState === 'content')
      ? (_openBlock(), _createBlock(_component_banner, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.viewState === 'content')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_achievement_header),
          _createElementVNode("form", {
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitUpdatedReview && _ctx.submitUpdatedReview(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('Add to Your Review')), 1),
            _createVNode(_component_review_progress, {
              onClickUpgradeButton: _ctx.submitUpdatedReview,
              isEligibleForUpgrade: _ctx.isEligibleForUpgrade,
              hasMedia: _ctx.hasMedia,
              isSending: _ctx.isSending
            }, null, 8, ["onClickUpgradeButton", "isEligibleForUpgrade", "hasMedia", "isSending"]),
            (_ctx.isMediaUploadSectionVisible)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("legend", {
                    class: _normalizeClass([{ 'u-paddingBottomUI--xSmall': !_ctx.mediaFromReview.length }, "u-fontSize-vue--regular u-fontWeight--semibold u-paddingTopUI--xSmall"])
                  }, _toDisplayString(_ctx.$t('Photos and Videos')), 3),
                  (_ctx.mediaFromReview.length)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('Thank you for your previous uploads, add more below')), 1),
                        _createElementVNode("div", _hoisted_5, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mediaFromReview, (media) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: media.streamId,
                              class: "c-currentMedia-block"
                            }, [
                              _createElementVNode("img", {
                                src: media.thumbnailUrl,
                                class: "c-currentMedia-block-img"
                              }, null, 8, _hoisted_6),
                              (media.durationSeconds)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.formatDuration(media.durationSeconds)), 1))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ])
                      ], 64))
                    : _createCommentVNode("", true),
                  _createVNode(_component_media_uploader, {
                    modelValue: _ctx.mediaBlocks,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mediaBlocks) = $event)),
                    class: "u-marginBottomUI--large"
                  }, null, 8, ["modelValue"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.isProfileSectionVisible)
              ? (_openBlock(), _createElementBlock("fieldset", _hoisted_8, [
                  _createVNode(_component_profile, { hasSubmitted: _ctx.hasSubmitted }, null, 8, ["hasSubmitted"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass([{
                    'u-marginBottomUI--xSmall': _ctx.isOptedIn,
                    'u-marginBottomUI--xxSmall': !_ctx.isOptedIn
                }, "c-form-row"])
            }, [
              _withDirectives(_createElementVNode("button", {
                "aria-label": _ctx.$t(_ctx.actionButtonText),
                class: "c-button"
              }, [
                (_ctx.isSending)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10))
                  : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t(_ctx.actionButtonText)), 1))
              ], 8, _hoisted_9), [
                [_directive_analytics, { eventName: 'click-update-review' }]
              ])
            ], 2),
            _createVNode(_component_terms, { hasPreviouslyOptedIn: true }),
            _createVNode(_component_powered_by_okendo)
          ], 32)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_view_state, {
      onRetry: _ctx.loadReviewEnhancement,
      "view-state": _ctx.viewState,
      errorCode: _ctx.errorCode
    }, null, 8, ["onRetry", "view-state", "errorCode"])
  ], 64))
}