
import { Options, Vue } from 'vue-class-component';

import store from '@/store';
import { CouponValue, Review } from '@okendo/reviews-common';
import Icon from '@/shared-components/Icon.vue';
import { isMobile } from '@/utils/deviceUtils';
import { getRewardCurrencyPlural } from '@/utils/rewardUtils';
import { isDark } from '@/utils/recorderStyling';
import Tooltip from '@/shared-components/Tooltip.vue';

@Options({
    components: {
        Icon,
        Tooltip
    }
})
export default class AchievementHeader extends Vue {
    couponTooltipDuration = 3000;
    couponCopied = false;
    showCouponTooltip = false;
    displayCouponCopiedMessageTimeout: number | undefined;
    onCouponTooltipMouseleaveTimeout: number | undefined;
    couponTooltipCloseDelay = 200;

    get hidePartyPopper(): boolean {
        const { settings } = store.state.settings;
        return settings?.hidePartyPopper === true;
    }

    getAchievedValueText(value: number, valueType: CouponValue.ValueType): string {
        return valueType === 'fixed_amount'
            ? this.$t('{currencySymbol}{value} off', { currencySymbol: this.currencySymbol, value })
            : this.$t('{value}% off', { value });
    }

    get isReviewEnhancementPage(): boolean {
        return this.$route.name === 'Review Enhancement';
    }

    get areAchievementHeadersVisible(): boolean {
        const { isPreviouslyReviewed } = store.state.product;
        return !(isPreviouslyReviewed && this.isReviewEnhancementPage);
    }

    get currencySymbol(): string {
        return store.state.subscriber.currencySymbol;
    }

    get achievement(): Achievement {
        const { reward, previouslyAchievedReward } = store.state.reward;
        const defaultSubmitText = store.state.review.reviewEnhanced ? 'Review Updated!' : 'Review Submitted!';

        switch (reward?.type) {
            case 'points':
                return {
                    ...reward,
                    value: reward.description,
                    description: reward.value === '1' ? 'Has been added to your account' : 'Have been added to your account',
                    headings: [defaultSubmitText, this.$t(`You've Earned {points}!`, { points: this.getTitleCaseRewardCurrencyPlural() })]
                };
            case 'event':
                return {
                    ...reward,
                    value: undefined,
                    description: undefined,
                    headings: [defaultSubmitText],
                    subheading: 'You may have qualified for a reward! Check the rewards program for more details'
                };
            case 'coupon':
                if (reward.isCouponUpgrade && previouslyAchievedReward) {
                    return {
                        ...reward,
                        previouslyAchieved: previouslyAchievedReward,
                        headings: ['Coupon Upgraded!'],
                        subheading: 'Please find your unique coupon code below. It has also been emailed to you'
                    };
                }
                return {
                    ...reward,
                    headings: [defaultSubmitText, `You've Earned a Coupon!`],
                    subheading: 'Please find your unique coupon code below. It has also been emailed to you'
                };
            default:
                return {
                    headings: [defaultSubmitText],
                    subheading: 'Thanks for sharing your feedback with us!'
                };
        }
    }

    get isCompleteCouponDark(): boolean {
        return isDark(store.state.settings.settings.colors?.couponCompleteColor ?? '#0E7D81');
    }

    get isIncompleteCouponDark(): boolean {
        return isDark(store.state.settings.settings.colors?.couponIncompleteColor ?? '#E5E5EC');
    }

    getTitleCaseRewardCurrencyPlural(): string {
        const rewardCurrency = getRewardCurrencyPlural();
        return rewardCurrency.replace(/\w\S*/g, text => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase());
    }

    async copyCouponToClipboard(): Promise<void> {
        const text = this.achievement.value;

        if (this.achievement.type !== 'coupon' || !text) {
            return;
        }

        this.showCouponTooltip = true;

        if (!navigator.clipboard) {
            this.fallbackCopyCouponToClipboard(text);
            return;
        }

        try {
            await navigator.clipboard.writeText(text);
            this.displayCouponCopiedMessage();
        }
        catch {
            this.hideCouponCopiedMessage();
        }
    }

    fallbackCopyCouponToClipboard(text: string): void {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        textarea.style.top = '0';
        textarea.style.left = '0';
        textarea.style.width = '0';
        textarea.style.height = '0';
        textarea.style.position = 'fixed';
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();

        try {
            if (document.execCommand('copy')) {
                this.displayCouponCopiedMessage();
            }
        }
        catch {
            this.hideCouponCopiedMessage();
        }

        document.body.removeChild(textarea);
    }

    displayCouponCopiedMessage(): void {
        this.couponCopied = true;

        if (isMobile()) {
            clearTimeout(this.displayCouponCopiedMessageTimeout);
            this.displayCouponCopiedMessageTimeout = setTimeout(() => {
                this.onCouponTooltipMouseleave();
            }, this.couponTooltipDuration);
        }
    }

    hideCouponCopiedMessage(): void {
        this.couponCopied = false;
    }

    onCouponTooltipMouseenter(): void {
        this.showCouponTooltip = true;
    }

    onCouponTooltipMouseleave(): void {
        this.showCouponTooltip = false;
        clearTimeout(this.onCouponTooltipMouseleaveTimeout);
        this.onCouponTooltipMouseleaveTimeout = setTimeout(() => {
            this.hideCouponCopiedMessage();
        }, this.couponTooltipCloseDelay);
    }
}

interface Achievement extends Partial<Review.AchievedReward> {
    headings?: string[];
    subheading?: string;
    previouslyAchieved?: CouponValue;
}
