import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "c-progressStep-icon u-marginBottomUI--xxSmall" }
const _hoisted_2 = ["height", "width"]
const _hoisted_3 = ["cx", "cy", "r", "stroke-dasharray", "stroke-width", "stroke"]
const _hoisted_4 = { class: "c-progressStep-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["c-progressStep", { isComplete: _ctx.isComplete, areAllStepsComplete: _ctx.areAllStepsComplete }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_icon, {
        icon: _ctx.icon,
        class: _normalizeClass([{
                    'c-icon--white': _ctx.isComplete,
                    'c-icon--reviewStepColor': !_ctx.isComplete
                }, "c-icon--reviewStep"])
      }, null, 8, ["icon", "class"])
    ]),
    (_openBlock(), _createElementBlock("svg", {
      height: _ctx.radius * 2,
      width: _ctx.radius * 2,
      class: "c-progressStep-ring"
    }, [
      _createElementVNode("circle", {
        cx: _ctx.radius,
        cy: _ctx.radius,
        r: _ctx.innerRadius,
        "stroke-dasharray": `${_ctx.circumference} ${_ctx.circumference}`,
        "stroke-width": _ctx.strokeWidth,
        stroke: _ctx.strokeColor,
        style: _normalizeStyle({ strokeDashoffset: _ctx.strokeDashoffset }),
        fill: "transparent"
      }, null, 12, _hoisted_3)
    ], 8, _hoisted_2)),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.text)), 1)
  ], 2))
}